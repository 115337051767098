
import { defineComponent, ref } from "vue";
import Multiselect from "@vueform/multiselect";
import CodeHighlighter2 from "@/components/highlighters/CodeHighlighter2.vue";

export default defineComponent({
  name: "example-2",
  components: {
    Multiselect,
    CodeHighlighter2
  },
  setup() {
    const example2 = ref({
      mode: "multiple",
      value: ["robin"],
      options: {
        batman: "Batman",
        robin: "Robin",
        joker: "Joker"
      }
    });

    const htmlCode = ` <Multiselect v-model="example2.value" v-bind="example2"></Multiselect>`;

    const jsCode = `const example2 = ref({
      mode: "multiple",
      value: ["robin"],
      options: {
        batman: "Batman",
        robin: "Robin",
        joker: "Joker"
      }
    });`;

    return {
      example2,
      htmlCode,
      jsCode
    };
  }
});
