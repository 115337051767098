import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card card-custom" }
const _hoisted_2 = { class: "card-body p-10 p-lg-15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Overview = _resolveComponent("Overview")
  const _component_Example1 = _resolveComponent("Example1")
  const _component_Example2 = _resolveComponent("Example2")
  const _component_Example3 = _resolveComponent("Example3")
  const _component_Example4 = _resolveComponent("Example4")
  const _component_Example5 = _resolveComponent("Example5")
  const _component_Example6 = _resolveComponent("Example6")
  const _component_Example7 = _resolveComponent("Example7")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_Overview),
      _createVNode(_component_Example1),
      _createVNode(_component_Example2),
      _createVNode(_component_Example3),
      _createVNode(_component_Example4),
      _createVNode(_component_Example5),
      _createVNode(_component_Example6),
      _createVNode(_component_Example7)
    ])
  ]))
}