
import { defineComponent, ref } from "vue";
import Multiselect from "@vueform/multiselect";
import CodeHighlighter2 from "@/components/highlighters/CodeHighlighter2.vue";

export default defineComponent({
  name: "example-1",
  components: {
    Multiselect,
    CodeHighlighter2
  },
  setup() {
    const example1 = ref({
      value: 0,
      options: ["Batman", "Robin", "Joker"]
    });

    const htmlCode = `<Multiselect v-model="example1.value" v-bind="example1"></Multiselect>`;

    const jsCode = `const example1 = ref({
  value: 0,
  options: ["Batman", "Robin", "Joker"]
});`;

    return {
      example1,
      htmlCode,
      jsCode
    };
  }
});
