
import { defineComponent, ref } from "vue";
import Multiselect from "@vueform/multiselect";
import CodeHighlighter2 from "@/components/highlighters/CodeHighlighter2.vue";

export default defineComponent({
  name: "example-3",
  components: {
    Multiselect,
    CodeHighlighter2
  },
  setup() {
    const example3 = ref({
      mode: "multiple",
      value: [],
      options: [
        { value: "batman", label: "Batman" },
        { value: "robin", label: "Robin", disabled: true },
        { value: "joker", label: "Joker" }
      ]
    });

    const htmlCode = `<Multiselect v-model="example3.value" v-bind="example3"></Multiselect>`;

    const jsCode = `const example3 = ref({
      mode: "multiple",
      value: [],
      options: [
        { value: "batman", label: "Batman" },
        { value: "robin", label: "Robin", disabled: true },
        { value: "joker", label: "Joker" }
      ]
    });`;

    return {
      example3,
      htmlCode,
      jsCode
    };
  }
});
